.whatsapp-button-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  }
  
  .whatsapp-button {
    background-color: #009737;
    border: none;
    color: white;
    padding: 5px 20px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .whatsapp-button:hover {
    background-color: #01571e;
    
  }
  
  .whatsapp-icon {
    width: 35px;
    height: 35px;
    margin-right: 10px;
    
  }
  