.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
  
}
.pushable {
  background: hsl(340deg 100% 32%);
  border-radius: 20px;
  /* border: 5px solid #e4dcdc; */
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
}

.front {
  display: block;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 16px;
  background: hsl(345deg 100% 47%);
  color: white;
  transform: translateY(-6px);
  font-weight: bold;
  box-shadow: inset 0px 0px 6px 3px #fea036;
}

.pushable:active .front {
  transform: translateY(-2px);
}
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: blue; /* Match your app's background color */
  /* Add more styles to match your app's theme */
}
@media (max-width: 768px) {
  .container {
    max-width: 1200px; /* Adjust as needed */
    margin: 0 auto;   /* Center the container */
  }

  .single-play1 {
    height: 300px; /* Adjust as needed */
    /* Add other styling properties as needed */
  }

  .single-play1 .image {
    position: relative;
    overflow: hidden;
  }
  
  .single-play1 .image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}